import request from '@/utils/request'
// 查询设备列表
export function listDevice (query) {
  return request({
    url: '/gx/gx-device/list',
    method: 'get',
    params: query
  })
}
// 查询设备分页
export function pageDevice (query) {
  return request({
    url: '/gx/gx-device/page',
    method: 'get',
    params: query
  })
}

// 查询设备详细
export function getDevice (data) {
  return request({
    url: '/gx/gx-device/detail',
    method: 'get',
    params: data
  })
}
// 新增设备
export function addDevice (data) {
  return request({
    url: '/gx/gx-device/add',
    method: 'post',
    data: data
  })
}
// 修改设备
export function updateDevice (data) {
  return request({
    url: '/gx/gx-device/edit',
    method: 'post',
    data: data
  })
}

// 删除设备
export function delDevice (data) {
  return request({
    url: '/gx/gx-device/delete',
    method: 'post',
    data: data
  })
}
// 批量分配员工
export function userDevice (data) {
  return request({
    url: '/gx/gx-device/pl/user',
    method: 'post',
    data: data
  })
}
// 批量分配员工
export function bmDevice (data) {
  return request({
    url: '/gx/gx-device/plbm',
    method: 'post',
    data: data
  })
}
